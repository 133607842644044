import { useState, useEffect } from "react";
import { useStyle } from "./asd-confirmation-page.style";
import { Loader } from "../../../../components/common/loader";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two";
import theme from "../../../../theme";

import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import {
  getASDUploadDocuments,
  getASDApplicationDetails,
  postASDApplicationData,
  postASDDocumentsData,
  deleteASDDocument,
} from "./asd-confirmation-page.api";
import { Upload } from "../../../../components/common/upload/index";
import axios from "axios";
import { DownLoad } from "../../../../components/common/download";
import request, { ParentUser } from "../../../../utils/authUtil";
import { Type } from "../../../../utils/document-types";
import { remove } from "lodash";
import { Input } from "../../../../components/common/input/index";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

interface schoolDocument {
  asd_document_uuid: string;
  asd_document_name: string | null;
  asd_document_presingned_url: string;
  asd_document_question: string;
  app_asd_document_url: string;
  asd_follow_up_answer: string;
  asd_follow_up_question: string;
  further_detail_required: boolean;
  isUploaded: boolean;
  stu_asd_doc_uuid: string;
}

const DocumentInfo = (props: Props) => {
  const parentID = ParentUser.uuid;
  const styles = useStyle();
  const { nextPage, prevPage, stdAppId } = props;
  const [loader, setLoader] = useState<boolean>(false);
  const schoolID = localStorage.getItem("school-id");
  const [hasDocumentsToUpload, setHasDocumentsToUpload] = useState<boolean>(false);
  const [isASDapplication, setIsASDapplication] = useState<boolean>(false);
  const [ASDDescription, setASDDescription] = useState<string>("");
  const [errorEdit, setErrorEdit] = useState<string>("");
  const [documentData, setDocumentData] = useState<schoolDocument[]>([
    {
      asd_document_uuid: "",
      asd_document_name: null,
      asd_document_presingned_url: "",
      asd_document_question: "",
      app_asd_document_url: "",
      asd_follow_up_answer: "",
      asd_follow_up_question: "",
      further_detail_required: false,
      isUploaded: false,
      stu_asd_doc_uuid: "",
    },
  ]);
  const [validationError, setValidationError] = useState<string[]>([]);

  const [isFile, setIsFile] = useState<string[]>([""]);

  const [er, setEr] = useState<any>({
    document: false,
    accept_certificate: false,
    index: "",
  });

  const [downError, setDownError] = useState<string[]>([]);

  useEffect(() => {
    setLoader(true);
    const fn = async()=>{
      
    let response = await getASDUploadDocuments(stdAppId, schoolID)
    if (response?.data?.results?.length > 0) {
      setHasDocumentsToUpload(true);
      const refinedData = response?.data?.results.map((docItem: any) => {
        if (docItem?.asd_document_name && docItem?.app_asd_document_url) {
          return {
            ...docItem,
            isUploaded: true,
          };
        } else {
          return {
            ...docItem,
            isUploaded: false,
          };
        }
      });
      setDocumentData(refinedData);
    }
    }

    fn();
      setLoader(false);
  }, [stdAppId, schoolID]);

  const handleInputChange = (value: string, ind: number) => {
    const state: any = [...documentData];
    state[ind].asd_follow_up_answer = value;
    if (value !== "") {
      const a = validationError.filter(
        (item) => item !== `followUpError${ind}`
      );
      setValidationError(a);
    }
    setDocumentData(state);
  };

  const getSignedUrl = async (
    schoolId: any,
    userId: any,
    documentType: any,
    fileName: any,
    index: any,
    id: string
  ) => {
    if (fileName) {
      const temp = [...downError];
      if (!fileName.match(/\.(jpg|jpeg|png|pdf|PDF|JPG|JPEG|PNG)$/)) {
        if (!temp.includes(`${index}`)) {
          temp.push(`${index}`);
        }
        setDownError(temp);

        const tmp = isFile;
        remove(tmp, (n) => n === id);
        setIsFile(tmp);
        setEr({
          ...er,
          document: false,
          accept_certificate: true,
          index: `${index}`,
        });
        return null;
      } else {
        if (temp.includes(`${index}`)) {
          const val = temp.indexOf(`${index}`);
          if (index > -1) {
            temp.splice(val, 1);
          }
        }
        setDownError(temp);
        setLoader(true);
        const res = await request({
          url: "/auth/parent-documents-upload-credentials",
          method: "post",
          data: {
            user_uuid: userId,
            school_uuid: schoolId,
            document_type: documentType,
            file_name: fileName,
          },
        });
        return res;
      }
    } else {
      return null;
    }
  };

  const awsUploadFile = async (url: any, fields: any, file: any) => {
    const formData = new FormData();
    formData.append("key", fields.key);
    formData.append("policy", fields.policy);
    formData.append("x-amz-algorithm", fields["x-amz-algorithm"]);
    formData.append("x-amz-credential", fields["x-amz-credential"]);
    formData.append("x-amz-date", fields["x-amz-date"]);
    formData.append("x-amz-signature", fields["x-amz-signature"]);
    formData.append("file", file);
    const res = await axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res;
  };

  useEffect(()=>{
    getASDApplicationDetails(stdAppId,schoolID)
    .then((res)=>{
      if(res?.data?.status_code === 1){
        setIsASDapplication(res?.data?.results?.is_asd_application)
        setASDDescription(res?.data?.results?.school_asd_description)
      }
    })
  },[])

  const change = (event: any, id: string, index: any) => {
    new Promise((res, rej) => {
      getSignedUrl(
        schoolID,
        parentID,
        Type.other,
        event.target?.files[0]?.name,
        index,
        id
      ).then((response) => {
        setLoader(false);
        if (response === null) {
          return;
        }
        if (response.data.status_code === 1) {
          res(response.data.results);
        } else if (response.data.status_code === -1) {
          rej(response.data);
        }
      });
    }).then((response: any) => {
      documentData.forEach((data) => {
        if (data.asd_document_uuid === id) {
          setDocumentData(
            documentData.map((item) => {
              if (item.asd_document_uuid === id) {
                return {
                  ...data,
                  asd_document_presingned_url: `${response.url}${response.fields.key}`,
                  app_asd_document_url: `${response.url}${response.fields.key}`,
                  asd_document_name: event.target?.files[0]?.name,
                  isValidFile: true,
                };
              } else {
                return item;
              }
            })
          );
        }
      });

      awsUploadFile(response.url, response.fields, event.target.files[0]).then(
        (r) => {
          setEr({
            ...er,
            document: false,
            accept_certificate: false,
          });
          setIsFile([...isFile, id]);
          const a = validationError.filter(
            (item) => item !== `docError${index}`
          );
          setValidationError(a);
          setLoader(false);
        }
      );
    });
  };


  const postASDdata = async (): Promise<any> => {
    setLoader(true);
    const error_list = checkValidation();
    if (error_list.length === 0) {
      const data = {
        is_asd_application:isASDapplication,
      }
      postASDApplicationData(stdAppId, schoolID, data).then(() => {
        if(isASDapplication && hasDocumentsToUpload ){
          postASDDocumentsData(stdAppId, schoolID, documentData).then(() => {
            nextPage();
          })
        }
        nextPage();
      })

    }
    else{
      if(documentData.length === 0){
        alert("Please add atleast one document")
      }
    }
    setLoader(false);
  };

  const checkValidation = () => {
    const errorHelper: string[] = [];
    documentData.forEach((document, index) => {
      if (document.asd_document_name === "" && isASDapplication) {
        errorHelper.push(`docError${index}`);
      }
      if (
        document.further_detail_required &&
        document.asd_follow_up_answer === "" && isASDapplication
      ) {
        errorHelper.push(`followUpError${index}`);
      }
    });
    setValidationError([...errorHelper]);
    return errorHelper;
  };


  const deleteDocument = async(id: string) => {
    setLoader(true);
    let data = {
      stu_app_asd_doc_uuid : id
    }
    try{
      await deleteASDDocument(stdAppId,schoolID,data);
      }
      catch(error){
        console.log("error",error)
      }
    let tmp = documentData.map((x) => {
      if (x.stu_asd_doc_uuid === id) {
        return {
          ...x,
          asd_document_name: "",
          asd_document_presingned_url: "",
          app_asd_document_url: "",
          asd_follow_up_answer: "",
          isUploaded: false,
          stu_asd_doc_uuid: "",
        };
      } else {
        return x;
      }
    });
    const x = isFile;
    remove(x, (n) => n === id);
    setIsFile(x);
    setDocumentData(tmp);
    setLoader(false);
  };

  const handleRadioChange = (bool: any, index: number)=>{
    if(documentData.some((item)=>item?.stu_asd_doc_uuid !== "")){
      setErrorEdit("Please delete all documents before changing this option")
    }
    else{
      setErrorEdit("");
      setIsASDapplication(bool);
    }
  }

  return (
    <div className={styles.container}>
      <Loader open={loader} />
      <div>
        <div className={styles.bodyContainer}>
          <Grid container justifyContent="center">
            <div className={styles.card}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <div>
                  <p>
                    <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                      arrow_back
                    </Icon>
                  </p>
                  <div>Application</div>
                </div>
                <ProgressBar total={6} steps={5} />
              </Box>

              <div style={{ marginBottom: 30 }}>
                <Welcome
                  title="ASD Confirmation Page"
                  description="Next: Educational Status"
                />
              </div>
              <Grid container direction="column">
              <Box mt="20px" mb="20px">
                {
                errorEdit?.length > 0
                ? <p style={{ color: `${theme.palette.error.main}` }}>{errorEdit}</p>
                : null
                }
                </Box>
              {ASDDescription ? <p>{ASDDescription}</p> : null}
                <Box>
                    <InputRadioButton
                      placeHolderOne="Yes"
                      placeHolderTwo="No"
                      answer={isASDapplication}
                      onChange={handleRadioChange}
                      index={0}
                      valueOne={true}
                      valueTwo={false}
                      isCheckString
                    />
                </Box>
                { isASDapplication && hasDocumentsToUpload ?
                documentData?.map((item: any, index: number) => (
                  <Box key={index}>
                    {item.isUploaded ? (
                      <DownLoad
                        id={`download-document${item.asd_document_uuid}`}
                        label={item.asd_document_question}
                        file={item.asd_document_name}
                        downloadLink={item.asd_document_presingned_url}
                        onDelete={() => {
                          deleteDocument(item.stu_asd_doc_uuid);
                        }}
                      />
                    ) : (
                      <Upload
                        id={`document-question${item.asd_document_uuid}`}
                        label={item.asd_document_question}
                        placeholder="Upload Document"
                        onChange={(e: any) =>
                          change(e, item.asd_document_uuid, index)
                        }
                        // isFile={isFile?.includes(item.stu_asd_doc_uuid)}
                        file={item.asd_document_name}
                        error={validationError.includes(`docError${index}`)}
                        onDelete={() => deleteDocument(item.stu_asd_doc_uuid)}
                      />
                    )}
                    {item.further_detail_required &&
                      item.asd_follow_up_question && (
                        <Input
                          label={item.asd_follow_up_question}
                          type="text"
                          value={item.asd_follow_up_answer}
                          onChange={(e: any) =>
                            handleInputChange(e.target.value, index)
                          }
                          error={validationError.includes(
                            `followUpError${index}`
                          )}
                        />
                      )}
                  </Box>
                )): null}
              </Grid>
              <Grid
                container
                direction="row"
                wrap="nowrap"
                justifyContent="space-around"
              >
                <div className={styles.buttonWidth}>
                  <Button
                    label="Next"
                    light
                    endIcon="navigate_next"
                    onClick={postASDdata}
                  />
                </div>
              </Grid>
            </div>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default DocumentInfo;
