import { useState, useEffect } from "react";
import { useStyle } from "./my-document.style";
import { AdminButton } from "../../../components/common/admin-button/index";
import Document from "./document";
import request3 from "../../../utils/authUtilv3";
import { Loader } from "../../../components/common/loader/index";
import { getASDDocument, postASDdocument } from "./doc-ques-api";
import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from "react-query";

type Props = {
  applicationID: string;
  ready: any;
  selectedGlobalDate?: any;
  currentGlobalDate?: any;
};

interface documentData {
  asd_document_uuid: string;
  asd_document_description: string;
  further_details_required: boolean;
  follow_up_asd_document_question: string;
}

interface postDocumentData {
  id: string;
  document: documentData[];
}

const ASDDocumentTab: React.FC<Props> = ({
  applicationID,
  ready,
  selectedGlobalDate,
  currentGlobalDate,
}) => {
  const classes = useStyle();
  const [loader, setLoader] = useState<boolean>(false);

  const default_state = [
    {
      asd_document_uuid: "",
      asd_document_description: "",
      further_details_required: false,
      follow_up_asd_document_question: "",
    },
  ];

  const [document, setDocument] = useState<documentData[]>(default_state);

  const [error, setError] = useState<string[]>([]);

  const addDocumentHandler = () => {
    ready(false);
    setDocument([
      ...document,
      {
        asd_document_uuid: "",
        asd_document_description: "",
        further_details_required: false,
        follow_up_asd_document_question: "",
      },
    ]);
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error: getDocumentError,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["document", applicationID, selectedGlobalDate],
    () => getASDDocument(applicationID),
    {
      select: (document) => document.data,
    }
  );
  const mutation: UseMutationResult<any, Error, postDocumentData> = useMutation(
    async ({ id, document }) => postASDdocument({ id, document }),
    {
      onSuccess: (res) => {
        if (res) {
          const temp: any = [];
          res.data.results.forEach((item: any) => {
            temp.push({
              asd_document_uuid: item.asd_document_uuid,
              asd_document_description: item.asd_document_description,
              further_details_required: item.further_details_required,
              follow_up_asd_document_question: item.further_details_required
                ? item.follow_up_asd_document_question
                : "",
            });
          });
          setDocument(temp);
          ready(true);
          setLoader(false);
        }
      },
      onError: (error) => {
        setLoader(false);
      },
    }
  );

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      if (Object.keys(data?.results).length !== 0) {
        const temp: any = [];
        data.results.forEach((docQuestions: any) => {
          temp.push({
            asd_document_uuid: docQuestions.asd_document_uuid,
            asd_document_description: docQuestions.asd_document_description,
            further_details_required: docQuestions.further_details_required,
            follow_up_asd_document_question: docQuestions.further_details_required
              ? docQuestions.follow_up_asd_document_question
              : "",
          });
        });
        setDocument(temp);
      } else {
        setDocument(default_state);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const addFollowUpHandler = (index: number) => {
    const temp: any = [...document];
    if (
      temp[index].asd_document_question_follow_up_school_application.length < 1
    ) {
      const temp2 = [
        ...temp[index].asd_document_question_follow_up_school_application,
        {
          follow_up_asd_document_question: "",
        },
      ];

      temp[index].asd_document_question_follow_up_school_application = temp2;

      setDocument(temp);
      ready(false);
    }
  };

  const onChange = (value: any, ind: number, type: string) => {
      ready(false);
      const xyz: any = [...document];
      const temp = [...error];
      if (type === "asd_document_description") {
      xyz[ind].asd_document_description = value;
    }

    setDocument(xyz);
    if ((type = "asd_document_description"))
      if (value.length === 0) {
        if (!temp.includes(`doc${ind}`)) {
          temp.push(`doc${ind}`);
        }
      } else if (temp.includes(`doc${ind}`)) {
        const val = temp.indexOf(`doc${ind}`);
        if (ind > -1) {
          temp.splice(val, 1);
        }
      }
    setError(temp);
  };
  const onFurChange = (value: any, ind: number, type: string) => {
    ready(false);
    const xyz: any = [...document];
    const temp = [...error];

    if (type === "further_details_required") {
      xyz[ind].further_details_required = value;
    }
    if (type === "asd_document_question_follow_up_school_application") {
      xyz[ind].follow_up_asd_document_question = value;
    }
    setDocument(xyz);
    if ((type = "asd_document_question_follow_up_school_application")) {
      if (value.length === 0) {
        if (!temp.includes(`${ind}`)) {
          temp.push(`${ind}`);
        }
      } else if (temp.includes(`${ind}`)) {
        const val = temp.indexOf(`${ind}`);
        if (ind > -1) {
          temp.splice(val, 1);
        }
      }
    }
    setError(temp);
  };

  const onSaveClick = () => {
    const temp = [...error];
    try {
      document.forEach((item: any, index: any) => {
        if (item.asd_document_description.length === 0) {
          if (!temp.includes(`doc${index}`)) {
            temp.push(`doc${index}`);
          }
        }
        if (
          item.further_details_required &&
          item.follow_up_asd_document_question.length === 0
        ) {
          if (!temp.includes(`${index}`)) {
            temp.push(`${index}`);
          }
        } else if (temp.includes(`${index}`)) {
          const val = temp.indexOf(`${index}`);
          if (index > -1) {
            temp.splice(val, 1);
          }
        }
      });
      if (temp.length !== 0) {
        throw temp;
      } else {
        setLoader(true);
        setError([]);
        mutation.mutate({ id: applicationID, document });
      }
    } catch (temp: any) {
      setError(temp);
    }
  };

  const deleteHandler = (
    questionId: string = "",
    followUpId: string = "",
    questionIndex: number,
    followUpIndex: number
  ) => {
    if (questionId.length !== 0) {
      setLoader(true);
      request3({
        url: `school-settings/school_asd_document_question/?application_uuid=${applicationID}`,
        method: "delete",
        data: {
          asd_document_uuid: questionId,
        },
      }).then(() => {
        if (questionId) {
          const temp = [...document];
          temp.splice(questionIndex, 1);
          setError([]);
          setDocument(temp);
          setLoader(false);
        } else if (followUpId) {
          const xyz: any = [...document];
          const temp = [...document];
          xyz[
            questionIndex
          ].asd_document_question_follow_up_school_application = temp;
          setError([]);
          setDocument(xyz);
          setLoader(false);
        }
      });
    } else {
      if (questionIndex && followUpIndex === 0) {
        const temp = [...document];
        temp.splice(questionIndex, 1);
        setDocument(temp);
        setError([]);
      }
    }
  };

  return (
    <div className={classes.documentContainer}>
      <Loader open={loader} />
      {isError ? (
        <div>
          <p>Error while Fetching data.....</p>
          <p>{getDocumentError}</p>
        </div>
      ) : null}
      {isSuccess ? (
        <div>
          {document.map((item: any, index: number) => {
            return (
              <div key={index} style={{ marginBottom: 50 }}>
                <Document
                  index={index}
                  documentLabel={`Document ${index + 1}`}
                  data={item}
                  furChange={onFurChange}
                  change={onChange}
                  addFollowUp={() => addFollowUpHandler(index)}
                  onDelete={deleteHandler}
                  errorArray={error}
                  selectedGlobalDate={selectedGlobalDate}
                  currentGlobalDate={currentGlobalDate}
                />
              </div>
            );
          })}
          {selectedGlobalDate === currentGlobalDate && (
            <>
              <div style={{ width: "230px", marginTop: "60px" }}>
                <AdminButton
                  label="Add further Documents"
                  endIcon="add"
                  light
                  onClick={addDocumentHandler}
                />
              </div>
              <div style={{ width: 100, marginTop: 60, marginBottom: 60 }}>
                <AdminButton label="Save" onClick={onSaveClick} />
              </div>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ASDDocumentTab;
