/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Welcome } from "../../../../components/common/welcome/index";
import { Button } from "../../../../components/common/button/index";
import { Input } from "../../../../components/common/input/index";
import { useStyle } from "./student-details.style";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import { Box } from "@material-ui/core";
import { InputRadioButton } from "../../../../components/common/input-radio-button-two";
import moment from "moment";
import { InputDatePicker } from "../../../../components/common/date-picker";
import { ProgressBar } from "../../../../components/common/progress-bar/index";
import { SelectDropDown } from "../../../../components/common/select-dropdown";
import {
  useQuery,
  useMutation,
  UseQueryResult,
  UseMutationResult,
} from "react-query";
import { Loader } from "../../../../components/common/loader/index";
import request from "../../../../utils/authUtil";
import requestv2 from "../../../../utils/authUtilv2";
import { dropDownHelper } from "../../../../utils/helper";
import { MessageBox } from "../../../../components/common/message-Box/index";

type Props = {
  nextPage?: any;
  prevPage?: any;
  stdAppId: string;
};

interface studentDetailsData {
  uuid: string;
  first_name: string;
  last_name: string;
  legal_first_name: string;
  legal_last_name: string;
  applictaion_id: null;
  application_status: string;
  date_of_birth: Date | null;
  PPSNumber: string;
  gender: string;
  country_of_birth: string;
  religion: string;
  religion_question_application: any;
  country_of_origin: string;
  nationality: string;
}

const getStudentData = async (stdAppId: string): Promise<any> => {
  const res = await request({
    url: `student-application/student-application-details/?student_app_uuid=${stdAppId}`,
    method: "get",
  });
  return res;
};

const postStudentData = async ({
  stdAppId,
  localData,
  motherTongueData,
}: any): Promise<any> => {
  const data = {
    student_app_uuid: localData.uuid,
    first_name: localData.first_name,
    last_name: localData.last_name,
    legal_first_name: localData.legal_first_name,
    legal_last_name: localData.legal_last_name,
    date_of_birth: moment(localData.date_of_birth).format("YYYY-MM-DD"),
    PPSNumber: localData.PPSNumber,
    gender: localData.gender,
    religion: localData.religion,
    country_of_origin: localData.country_of_origin,
    nationality: localData.nationality,
  };
  const res = await request({
    url: `student-application/student-application-details/`,
    method: "put",
    data,
  });
  const res2 = await requestv2({
    url: `student-application/student-mother-tongue-ethnicity/?stu_app_uuid=${localData.uuid}`,
    method: "put",
    data: motherTongueData,
  });
  return res;
};

const getReligions = async () => {
  return await request({
    url: `student-application/student-application-religions-list/`,
    method: "get",
  });
};

const getMotherTongueAndEthnicityList = async () => {
  const ethnicityList = await requestv2({
    url: `school-settings/ethnicity-cultural-background-list/`,
    method: "get",
  });
  return { ethnicityList };
};

const getMotherTongueAndEthnicity = async (uuid: any) => {
  const motherTongueAndEthnicity = await requestv2({
    url: `student-application/student-mother-tongue-ethnicity/?stu_app_uuid=${uuid}`,
    method: "get",
  });
  return motherTongueAndEthnicity;
};

const getCountry = async () => {
  return await request({
    url: `student-application/country-details/`,
    method: "get",
  });
};

const getNationality = async () => {
  return await request({
    url: `student-application/nationality-details`,
    method: "get",
  });
};

const StudentPersonalInfo = (props: Props) => {
  const styles = useStyle();
  const schoolID = localStorage.getItem("school-id");
  const [isCountry, setIsCountry] = useState("OPTIONAL");
  const [isNationality, setIsNationality] = useState("OPTIONAL");
  const { nextPage, prevPage, stdAppId } = props;
  const [religion, setReligion] = useState([
    { name: "-- Select Religion --", value: "" },
  ]);
  const [country, setCountry] = useState([
    { name: "-- Select Country --", value: "" },
  ]);
  const [nationality, setNationality] = useState([
    { name: "-- Select Nationality --", value: "" },
  ]);
  const [motherTongue, setMotherTongue] = useState("");
  const [ethnicity, setEthnicity] = useState([
    { name: "-- Select Ethnicity --", value: "" },
  ]);
  const [selectedEthnicity, setSelectedEthnicity] = useState("");
  const [localData, setLocalData] = useState<studentDetailsData>({
    uuid: "",
    first_name: "",
    last_name: "",
    legal_first_name: "",
    legal_last_name: "",
    applictaion_id: null,
    application_status: "",
    date_of_birth: null,
    PPSNumber: "",
    gender: "",
    country_of_birth: "",
    religion: "",
    country_of_origin: "",
    religion_question_application: null,
    nationality: "",
  });
  const [showGenderQuestion, setShowGenderQuestion] = useState<boolean>(false);
  const [showMotherTongueEthnicity, setShowMotherTongueEthnicity] =
    useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<any>({
    first_name: false,
    last_name: false,
    legal_first_name: false,
    legal_last_name: false,
    PPSNumber: false,
    date: false,
    country_of_origin: false,
    nationality: false,
    mother_tongue: false,
    ethnicity: false,
  });
  const [duplicate, setDuplicate] = useState<boolean>(false);

  const handleChangeRadio = (name: string, index: number) => {
    setLocalData({
      ...localData,
      gender: name,
    });
    setError({
      ...error,
      gender: false,
    });
  };
  const handleChangeMotherTongueRadio = (name: string, index: number) => {
    setMotherTongue(name);
    setError({
      ...error,
      mother_tougue: false,
    });
  };

  const {
    isLoading,
    isError,
    isSuccess,
    error: getStdDetailsError,
    data,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    ["stddetails", stdAppId],
    () => getStudentData(stdAppId),
    {
      select: (res) => res.data,
    }
  );

  const mutation: UseMutationResult<any, Error, any> = useMutation(
    async ({ stdAppId, localData, motherTongueData }) =>
      postStudentData({ stdAppId, localData, motherTongueData }),
    {
      onSuccess: (res) => {
        if (
          res.data.status_code === -1 &&
          res.data.status_message === "This is duplicate application."
        ) {
          setLoader(false);
          setDuplicate(true);
        } else if (res.data.status_code === 1) {
          setLoader(false);
          nextPage();
        }
      },
      onError: (e) => {
        setLoader(false);
      },
    }
  );

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  useEffect(() => {
    getReligions().then((res) => {
      const result = dropDownHelper({
        items: res.data.results,
        name: "religion_name",
        value: "religion_name",
      });
      setReligion([...religion, ...result]);
    });
    getCountry().then((res) => {
      const result = dropDownHelper({
        items: res.data.results,
        name: "name",
        value: "value",
      });
      setCountry([...country, ...result]);
    });
    getNationality().then((res) => {
      const result = dropDownHelper({
        items: res.data.results,
        name: "nationality_adjective",
        value: "nationality_uuid",
      });
      setNationality([...nationality, ...result]);
    });
    getMotherTongueAndEthnicityList().then((res: any) => {
      const result2 = dropDownHelper({
        items: res?.ethnicityList?.data?.results,
        name: "key",
        value: "value",
      });
      setEthnicity([...ethnicity, ...result2]);
    });
  }, []);

  useEffect(() => {
    if (localData.uuid) {
      getMotherTongueAndEthnicity(localData.uuid).then((res) => {
        let resData = res?.data?.results;
        if (resData) {
          setMotherTongue(resData?.mother_tongue);
          if (resData?.ethnicity_cultural_background) {
            setSelectedEthnicity(resData?.ethnicity_cultural_background);
          }
        }
      });
    }
  }, [localData.uuid]);

  useEffect(() => {
    request({
      url: `student-application/student-application-custom-settings/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      setShowMotherTongueEthnicity(
        res?.data?.results?.is_show_mother_tongue_ethnicity &&
          res?.data?.results?.mother_tongue_ethnicity_in_application
      );
      setShowGenderQuestion(res?.data?.results?.is_show_gender_question);
      setIsCountry(res.data.results.is_country_of_origin);
      setIsNationality(res.data.results.is_nationality);
    });
  }, []);

  useEffect(() => {
    if (data && Object.keys(data?.results).length !== 0) {
      setLocalData({
        ...localData,
        uuid: data.results.uuid,
        first_name: data.results.first_name,
        last_name: data.results.last_name,
        legal_first_name: data.results?.legal_first_name || "",
        legal_last_name: data.results?.legal_last_name || "",
        applictaion_id: data.results.applictaion_id,
        application_status: data.results.application_status,
        date_of_birth:
          data.results.date_of_birth !== null
            ? new Date(data.results.date_of_birth)
            : null,
        PPSNumber: data.results.PPSNumber === 0 ? "" : data.results.PPSNumber,
        gender: data.results?.gender,
        country_of_birth: data.results.country_of_birth,
        religion: data.results.religion,
        religion_question_application:
          data.results.religion_question_application,
        country_of_origin: data.results.country_of_origin,
        nationality: data.results.nationality,
      });
    }
  }, [data]);

  const validate = () => {
    return {
      first_name: localData.first_name.length === 0,
      last_name: localData.last_name.length === 0,
      legal_first_name: localData.legal_first_name.length === 0,
      legal_last_name: localData.legal_last_name.length === 0,
      PPSNumber: localData.PPSNumber.length === 0,
      date: localData.date_of_birth === null,
      country_of_origin:
        isCountry === "MANDATORY"
          ? localData.country_of_origin.length === 0
          : false,
      nationality:
        isNationality === "MANDATORY"
          ? localData.nationality.length === 0
          : false,
      motherTongue: showMotherTongueEthnicity && motherTongue === "",
      ethnicity: showMotherTongueEthnicity && selectedEthnicity === "",
    };
  };

  const setErrorOnSubmit = () => {
    const err = validate();
    setError(err);
    if (
      err.first_name === false &&
      err.last_name === false &&
      err.legal_first_name === false &&
      err.legal_last_name === false &&
      err.PPSNumber === false &&
      err.date === false &&
      err.country_of_origin === false &&
      err.nationality === false &&
      err.motherTongue === false &&
      err.ethnicity === false
    ) {
      setLoader(true);
      const motherTongueData = {
        mother_tongue: motherTongue,
        ethnicity_cultural_background: selectedEthnicity,
      };
      mutation.mutate({ stdAppId, localData, motherTongueData });
    }
  };

  const onValueChange = (event: any, inputType: string) => {
    switch (inputType) {
      case "firstName":
        setLocalData({
          ...localData,
          first_name: event.target.value,
        });
        setError({
          ...error,
          first_name: event.target.value.length === 0,
        });
        break;
      case "legalFirstName":
        setLocalData({
          ...localData,
          legal_first_name: event.target.value,
        });
        setError({
          ...error,
          legal_first_name: event.target.value.length === 0,
        });
        break;
      case "legalLastName":
        setLocalData({
          ...localData,
          legal_last_name: event.target.value,
        });
        setError({
          ...error,
          legal_last_name: event.target.value.length === 0,
        });
        break;
      case "lastName":
        setLocalData({
          ...localData,
          last_name: event.target.value,
        });
        setError({
          ...error,
          last_name: event.target.value.length === 0,
        });
        break;
      case "date":
        setLocalData({
          ...localData,
          date_of_birth: event,
        });
        setError({
          ...error,
          date: event === null,
        });
        break;
      case "pps":
        setLocalData({
          ...localData,
          PPSNumber: event.target.value,
        });
        setError({
          ...error,
          PPSNumber: event.target.value.length === 0,
        });
        break;
      case "religion":
        setLocalData({
          ...localData,
          religion: event.target.value,
        });
        break;
      case "country_of_origin":
        setLocalData({
          ...localData,
          country_of_origin: event.target.value,
        });
        setError({
          ...error,
          country_of_origin: event.target.value.length === 0,
        });
        break;
      case "nationality":
        setLocalData({
          ...localData,
          nationality: event.target.value,
        });
        setError({
          ...error,
          nationality: event.target.value.length === 0,
        });
        break;
    }
  };

  const saveStudentData = () => {
    setErrorOnSubmit();
  };

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  const classes = useStyle();

  return (
    <div className={styles.container}>
      <div className={styles.bodyContainer}>
        <Grid container justifyContent="center">
          <div className={styles.card}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <div>
                <p>
                  <Icon onClick={prevPage} style={{ cursor: "pointer" }}>
                    arrow_back
                  </Icon>
                </p>
                <div>Application</div>
              </div>
              <ProgressBar total={6} steps={1} />
            </Box>
            <div style={{ marginBottom: 30 }}>
              <Welcome
                title="Student/Child details"
                description="Next: Guardian details"
              />
            </div>
            {isError ? (
              <div>
                <p>Error while Fetching data.....</p>
                <p>{getStdDetailsError}</p>
              </div>
            ) : null}
            {isSuccess ? (
              <Grid container direction="column">
                <Input
                  label="First name *"
                  value={localData.first_name}
                  onChange={(e) => onValueChange(e, "firstName")}
                  error={error.first_name}
                />
                <Input
                  label="Last name *"
                  value={localData.last_name}
                  onChange={(e) => onValueChange(e, "lastName")}
                  error={error.last_name}
                />
                <Input
                  label="Birth Certificate Forename*"
                  value={localData.legal_first_name}
                  onChange={(e) => onValueChange(e, "legalFirstName")}
                  error={error.legal_first_name}
                />
                <Input
                  label="Birth Certificate Surname*"
                  value={localData.legal_last_name}
                  onChange={(e) => onValueChange(e, "legalLastName")}
                  error={error.legal_last_name}
                />
                <Box></Box>
                <Box>
                  <InputDatePicker
                    label="Date of birth *"
                    value={localData.date_of_birth}
                    onChange={(e: any) => onValueChange(e, "date")}
                    error={error.date}
                    maxDate={moment().subtract(10, "years").toDate()}
                  />
                </Box>
                <Input
                  label="PPS number *"
                  value={localData.PPSNumber}
                  onChange={(e: any) => onValueChange(e, "pps")}
                  error={error.PPSNumber}
                />
                {localData.religion_question_application ? (
                  <SelectDropDown
                    label="Religion"
                    item={religion}
                    value={localData.religion}
                    onChange={(e: any) => onValueChange(e, "religion")}
                  />
                ) : null}
                {showGenderQuestion ? (
                  <Box>
                    <InputRadioButton
                      label="Gender"
                      placeHolderOne="FEMALE"
                      placeHolderTwo="MALE"
                      answer={localData.gender}
                      onChange={handleChangeRadio}
                      index={0}
                      valueOne="FEMALE"
                      valueTwo="MALE"
                      isCheckString
                      // error={error.gender}
                    />
                  </Box>
                ) : null}
                <Box>
                  {isCountry !== "HIDE" && (
                    <SelectDropDown
                      label={
                        isCountry === "MANDATORY"
                          ? "Country of Origin *"
                          : "Country of Origin"
                      }
                      item={country}
                      value={localData.country_of_origin}
                      onChange={(e: any) =>
                        onValueChange(e, "country_of_origin")
                      }
                      error={
                        isCountry === "MANDATORY" && error.country_of_origin
                      }
                    />
                  )}
                </Box>
                <Box>
                  {isNationality !== "HIDE" && (
                    <SelectDropDown
                      label={
                        isNationality === "MANDATORY"
                          ? "Nationality *"
                          : "Nationality"
                      }
                      item={nationality}
                      value={localData.nationality}
                      onChange={(e: any) => onValueChange(e, "nationality")}
                      error={isNationality === "MANDATORY" && error.nationality}
                    />
                  )}
                </Box>
                {showMotherTongueEthnicity ? (
                  <Box>
                    <InputRadioButton
                      label="Is English or Irish the Mother Tongue of the student? * (MOTHER TONGUE is the language a child speaks as their
                      first language)"
                      placeHolderOne="YES"
                      placeHolderTwo="NO"
                      answer={motherTongue}
                      onChange={handleChangeMotherTongueRadio}
                      index={0}
                      valueOne={true}
                      valueTwo={false}
                      isCheckString
                      error={error.motherTongue}
                    />
                  </Box>
                ) : null}
                {showMotherTongueEthnicity ? (
                  <Box>
                    <p className={styles.disclamer}>
                      IN RESPECT OF THE NEXT QUESTION YOU MAY OPT NOT TO PROVIDE
                      AN ANSWER
                    </p>
                    <SelectDropDown
                      label={
                        "To which ethnic or cultural background does the above-named student belong? *"
                      }
                      item={ethnicity}
                      value={selectedEthnicity}
                      onChange={(e: any) => {
                        if (e.target.value) {
                          setSelectedEthnicity(e.target.value);
                          setError((err: any) => ({
                            ...err,
                            ethnicity: false,
                          }));
                        }
                      }}
                      error={error.ethnicity}
                    />
                  </Box>
                ) : null}

                <Grid
                  container
                  direction="row"
                  wrap="nowrap"
                  justifyContent="space-around"
                >
                  <div className={styles.buttonWidth}>
                    <Button
                      label="Next"
                      light
                      endIcon="navigate_next"
                      onClick={saveStudentData}
                    />
                  </div>
                </Grid>
              </Grid>
            ) : null}
          </div>
        </Grid>
        <MessageBox
          message="An application has already been submitted for this student. Please contact support@uniqueschools.ie"
          open={duplicate}
          setOpen={setDuplicate}
        />
        <Loader open={loader} />
      </div>
    </div>
  );
};

export default StudentPersonalInfo;
