import { Box } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import { yellow } from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import { useCallback, useEffect, useState } from "react";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import request from "../../../utils/authUtil";
import { useStyle } from "./applications-admin.style";
import { useHistory } from "react-router-dom";
import { Loader } from "../../../components/common/loader";
import { AdminButton } from "../../../components/common/admin-button";
import {
  Paper,
  Table,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
} from "@material-ui/core";
import Filter from "../offers/filter";
import classNames from "classnames";
import { ConfirmBox } from "../../../components/common/confirm-box";
import { MessageBox } from "../../../components/common/message-Box";
import Tooltip from "@material-ui/core/Tooltip";

const editableStatus = {
  Applied: [
    { name: "Selected", value: "ACCEPTED" },
    { name: "Cancelled", value: "CANCELLED" },
    { name: "Waiting", value: "WAITING" },
  ],
  Offered: [{ name: "Decline", value: "DECLINE" }],
  "Accepted Offer": [{ name: "Decline", value: "DECLINE" }],
  Waiting: [{ name: "Cancelled", value: "CANCELLED" }],
  Decline : [
    { name: "Selected", value: "ACCEPTED" },
  ],
  Selected: [
    {name:'applied',value:'APPLIED'},
    {name: 'Cancelled', value: 'CANCELLED'},
  ]
};

interface ApplicationData {
  ID: any;
  student_app_uuid: string;
  Student_Name: string;
  Email: string;
  Guardian_1: string;
  Submission_Date: string;
  application_status: string;
  waiting_list_number: any;
  waiting_list_position: any;
  application_reviewed_status: string;
  isEdit: boolean;
  isStatusDrop: boolean;
  dropItems: any[];
  selectedDropStatus: any;
}

interface RequestStatus {
  status_code: number;
  status_message: string;
  open_modal: boolean;
}

const DEFAULT_REQUEST_STATUS: RequestStatus = {
  status_code: 0,
  status_message: "",
  open_modal: false,
}


const Applications: React.FC = () => {
  const classes = useStyle();
  const schoolId = localStorage.getItem("school-id");
  const history = useHistory();
  const [applications, setApplications] = useState<ApplicationData[]>();
  const [currApplication, setCurrApplication] = useState<ApplicationData>();
  const [loader, setLoader] = useState<boolean>(false);
  const [sortTag, setSortTag] = useState<string>("");
  const [isStatusPopup, setIsStatusPopup] = useState<boolean>(false);
  const [isAsc, setIsAsc] = useState<boolean | string>(true);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalApplicants, setTotalApplicants] = useState<number>(0);
  const [lateApplicationCount, setLateApplicationCount] = useState<number>(0);
  const [totalFilteredApplicants, setTotalFilteredApplicants] =
    useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [status, setStatus] = useState<any>();
  const [currentStatus, setCurrentStatus] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [error, setError] = useState<string>("");
  const selectedGlobalDate = localStorage.getItem("selectedGlobalDate");
  const [ requestStatus, setRequestStatus] = useState<RequestStatus>(DEFAULT_REQUEST_STATUS)
  const getApplications = async (): Promise<any> => {
    const res = await request({
      url: `/school-settings/applicants-list/?school_uuid=${schoolId}&year_uuid=${selectedGlobalDate}&page=${pageNumber}&search_text=${searchText}&sorting_var=${sortTag}&is_asc=${isAsc}&status=${currentStatus}`,
      method: "get",
    });
    return res;
  };

  const {
    isLoading,
    isError,
    error: getTemplateError,
    data: tableData,
    refetch,
  }: UseQueryResult<any, Error> = useQuery<any, Error>(
    [
      "applicationsList",
      {
        pageNumber,
        searchText,
        isAsc,
        sortTag,
        selectedGlobalDate,
        currentStatus,
      },
    ],
    () => getApplications(),
    {
      select: (applciations) => applciations.data.results,
    }
  );

  const exportCsv = async () => {
    setLoader(true);
    await request({
      url: `/school-settings/export-csv-applications-data/?year_uuid=${selectedGlobalDate}&status=${currentStatus}`,
      method: "get",
    }).then((res) => setLoader(false));
  };

  const getOfferStatus = useCallback((): any => {
    setLoader(true);
    request({
      url: `/school-settings/application-status-list/?school_uuid=${schoolId}`,
      method: "get",
    }).then((res) => {
      if (res.data.status_code === 1) {
        setStatus([
          {
            name: "All",
            value: "",
          },
          ...res.data?.results
            .filter((item: any) => item.value !== "DRAFT")
            .map((item: any) => {
              return {
                name: item.key,
                value: item.value,
              };
            }),
          {
            name: "Late Applications",
            value: "Late Applications",
          },
          {
            name: "Reviewed",
            value: "REVIEWED",
          },
          {
            name: "Pending",
            value: "PENDING",
          },
          {name: "ASD",
            value: "ASD",
          }
        ]);
        setLoader(false);
      } else {
        setLoader(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onStatusChange = (item: any) => {
    let filterString:any = localStorage?.getItem("filterState")
    let filter = JSON?.parse(filterString)
    localStorage.setItem("filterState", JSON.stringify({ ...filter, "application": item.value }));
    setCurrentStatus(item.value);
  };

  useEffect(() => {
    getOfferStatus();
    let filterString:any = localStorage?.getItem("filterState")
    let applicationFilter = JSON?.parse(filterString)?.application

    if(applicationFilter){
      setCurrentStatus((prev)=>applicationFilter)
    }
  }, [getOfferStatus]);


  useEffect(() => {
    if (tableData) {
      if (Object.keys(tableData).length !== 0) {
        setTotalApplicants(tableData.total_no_of_applicants);
        setTotalFilteredApplicants(tableData.total_no_of_filters_applicants);
        setLateApplicationCount(tableData.late_application_count);
        let list: any[] = [];
        tableData?.applicants_list.map((applicant: any) => {
          let item = {
            ...applicant,
            isEdit: false,
            isStatusDrop: false,
            dropItems: Object.keys(editableStatus).includes(
              applicant.application_status
            )
              ? editableStatus[
                  applicant.application_status as keyof typeof editableStatus
                ]
              : [],
            selectedDropStatus: {
              name: applicant.application_status,
              value: "",
            },
          };
          list.push(item);
          return list;
        });
        setApplications(list);
        setError("");
        setTotalPages(tableData.total_pages);
        setPageNumber(tableData.current_page);
      } else {
        setError("No data exist");
        setApplications([]);
        setTotalFilteredApplicants(0);
      }
    }
  }, [tableData]);

  useEffect(() => {
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  const columns = [
    { id: "ID", label: "ID", minWidth: 100, isSort: true },
    { id: "Student_Name", label: "Student Name", minWidth: 150, isSort: true },
    {
      id: "Guardian",
      label: "Guardian 1",
      minWidth: 150,
      isSort: true,
    },
    { id: "Email", label: "Email", minWidth: 100, isSort: true },
    { id: "app_status", label: "Application Status", minWidth: 100 },
    {
      id: "waiting_list_number",
      label: "Waiting List Number",
      minWidth: 100,
      isSort: true,
    },
    {
      id: "waiting_list_position",
      label: "Waiting List Position",
      minWidth: 100,
      isSort: true,
    },
    {
      id: "Submission_Date",
      label: "Submission Date",
      minWidth: 120,
      align: "right",
      isSort: true,
    },
    {
      id: "Reviewed",
      label: " ",
      minWidth: 30,
      align: "right",
    },
    {
      id: "Edit",
      label: " ",
      minWidth: 100,
      align: "right",
    },
  ];

  const searchTextChange = (text: string) => {
    if (text.length >= 3 || text.length === 0) {
      setSearchText(text);
    }
  };

  const firstLastPageHandler = (btnClicked: string) => {
    if (btnClicked === "first") {
      setPageNumber(1);
    } else {
      setPageNumber(totalPages);
    }
  };

  const statusOptionClick = (data: any, id: any) => {
    setApplications((prev) => {
      return prev?.map((item) => {
        if (item.ID === id) {
          return {
            ...item,
            selectedDropStatus: data,
          };
        } else {
          return item;
        }
      });
    });
    setIsStatusPopup(true);
  };

  const statusDropdownHandler = (id: any) => {
    setApplications((prev) => {
      return prev?.map((item) => {
        if (item.ID === id) {
          return {
            ...item,
            isStatusDrop: !item.isStatusDrop,
          };
        } else {
          return item;
        }
      });
    });
  };

  const editStatusHandler = (id: any) => {
    setApplications((prev) => {
      return prev?.map((item) => {
        if (item.ID === id) {
          return {
            ...item,
            isEdit: true,
          };
        } else {
          return item;
        }
      });
    });
  };

  const changeStatus = async (body: any): Promise<any> => {
    const res = await request({
      url: `/school-settings/student-application-status-change/`,
      method: "put",
      data: {
        ...body,
      },
    });
    return res;
  };

  const mutation: UseMutationResult<any, Error> = useMutation(
    async (body: any) => changeStatus(body),
    {
      onSuccess: (res) => {
        if(res?.data?.status_code < 0){
        let response = {
          status_code:res.data.status_code,
          status_message:res.data.status_message,
          open_modal:true
        }
        setRequestStatus(response)
      }
        refetch();
        setLoader(false);
      },
      onError: (res) => {
        setLoader(true);
      },
    }
  );

  const confirmStatusChange = (id: any, stdId: string, status: any) => {
    const data = {
      student_app_uuid: stdId,
      application_status: status,
    };
    setLoader(true);
    mutation.mutate(data);
  };

  const cancelStatusChange = (id: any, previousStatus: string) => {
    setApplications((prev) => {
      return prev?.map((item) => {
        if (item.ID === id) {
          return {
            ...item,
            isEdit: false,
            selectedDropStatus: { name: previousStatus, value: "" },
          };
        } else {
          return item;
        }
      });
    });
  };

  const closeMessageBox = ()=>{
    cancelStatusChange(currApplication?.ID, currApplication?.selectedDropStatus?.name)
    setRequestStatus(DEFAULT_REQUEST_STATUS)
  }

  return (
    <Grid container direction="column" justifyContent="flex-start">
      <Loader open={loader} />
      <Box mb="40px">
        {isError ? (
          <div>
            <p>Error while Fetching data.....</p>
            <p>{getTemplateError}</p>
          </div>
        ) : null}
        <div style={{ margin: "-85px 0px 50px 0px" }}>
          <>
            <Box mt="90px">
              <Box className={classes.heading}>Applications</Box>
            </Box>
            <Box className={classes.boxStyle}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="block">
                  <p>Your applicants</p>
                  <p>
                    You have received <strong>{`${totalApplicants}`}</strong>{" "}
                    applications in total.
                  </p>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  borderBottom="1px solid #000000"
                >
                  <input
                    onChange={(e: any) => searchTextChange(e.target.value)}
                    type="text"
                    placeholder="Find an applicant"
                    className={classes.inputStyle}
                  />
                  <div>
                    <Icon className={classes.iconStyle}>search</Icon>
                  </div>
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row-reverse"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex">
                  <AdminButton
                    onClick={exportCsv}
                    label="Export Excel"
                    disabled={totalApplicants === 0}
                  />
                </Box>
                <Box
                  marginTop="20px"
                  display="flex"
                  alignItems="center"
                  gridGap={10}
                >
                  {status && (
                    <Filter
                      options={status}
                      checkboxClick={onStatusChange}
                      label="Application Status"
                      defaultVal={currentStatus}
                    />
                  )}
                  <span>
                    Total filtered applicants :{" "}
                    <strong>{`${totalFilteredApplicants}`}</strong>
                  </span>
                  <Box marginLeft="20px">
                    Late application count :{" "}
                    <strong>{`${lateApplicationCount}`}</strong>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Paper
              style={{
                width: "94%",
                overflow: "hidden",
                marginTop: "20px",
              }}
            >
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          className={classes.root}
                          key={column.id}
                          style={{ minWidth: column.minWidth }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="start"
                          >
                            {column.label}
                            {column.isSort && (
                              <>
                                <Box marginLeft="15px">
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                      color: "#757575",
                                      fontSize: "18px",
                                    }}
                                    onClick={() => {
                                      setSortTag(column.id);
                                      setIsAsc(true);
                                    }}
                                  >
                                    arrow_upward_icon
                                  </Icon>
                                  <Icon
                                    style={{
                                      cursor: "pointer",
                                      color: "#757575",
                                      fontSize: "18px",
                                    }}
                                    onClick={() => {
                                      setSortTag(column.id);
                                      setIsAsc("");
                                    }}
                                  >
                                    arrow_downward_icon
                                  </Icon>
                                </Box>
                              </>
                            )}
                          </Box>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {applications &&
                      applications.map((item, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            key={`${item?.ID}${index}`}
                            tabIndex={-1}
                          >
                            <>
                              <TableCell className={classes.root}>
                                {item.ID}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.Student_Name}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.Guardian_1}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.Email}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {Object.keys(editableStatus).includes(
                                  item.application_status
                                ) ? (
                                  item.isEdit ? (
                                    <Box position="relative">
                                      <div>
                                        <Box
                                          display="flex"
                                          alignItems="center"
                                          fontWeight={600}
                                          onClick={() =>
                                            statusDropdownHandler(item.ID)
                                          }
                                        >
                                          <Box
                                            width="150px"
                                            className={classes.select}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                          >
                                            <p className={classes.textOne}>
                                              {item.selectedDropStatus?.name}
                                            </p>
                                            <Icon className={classes.arrowIcon}>
                                              {item.isStatusDrop
                                                ? "keyboard_arrow_up_icon"
                                                : "keyboard_arrow_down_icon"}
                                            </Icon>
                                          </Box>
                                          <Icon
                                            onClick={() => {
                                              cancelStatusChange(
                                                item.ID,
                                                item.application_status
                                              );
                                            }}
                                            className={classes.closeIconStyle}
                                          >
                                            cancel
                                          </Icon>
                                        </Box>
                                        <Box
                                          className={
                                            item.isStatusDrop
                                              ? classes.dropContainer
                                              : classes.displayNone
                                          }
                                        >
                                          <div>
                                            {item.dropItems?.map(
                                              (obj: any, index: number) => (
                                                <Box
                                                  className={classNames(
                                                    classes.root2,
                                                    index !==
                                                      item.dropItems?.length - 1
                                                      ? classes.itemBorder
                                                      : ""
                                                  )}
                                                  onClick={() =>
                                                    statusOptionClick(
                                                      obj,
                                                      item.ID
                                                    )
                                                  }
                                                >
                                                  <Box
                                                    padding="10px"
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                  >
                                                    <div>{obj.name}</div>
                                                  </Box>
                                                </Box>
                                              )
                                            )}
                                          </div>
                                        </Box>
                                      </div>
                                      <ConfirmBox
                                        message="Are your sure you want to change the current application status?"
                                        open={isStatusPopup}
                                        setOpen={setIsStatusPopup}
                                        onClick={() =>
                                          confirmStatusChange(
                                            item.ID,
                                            item.student_app_uuid,
                                            item.selectedDropStatus.value
                                          )
                                        }
                                        handleCancel={() =>
                                          cancelStatusChange(
                                            item.ID,
                                            item.application_status
                                          )
                                        }
                                      />
                                    <MessageBox
                                      message={requestStatus.status_message}
                                      open={requestStatus.open_modal}
                                      setOpen={closeMessageBox}
                                    />
                                    </Box>
                                  ) : (
                                    <div className={classes.beforeEdit}>
                                      {item.application_status}
                                      <Icon
                                        style={{
                                          color: yellow[700],
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          {
                                            setCurrApplication(item);
                                            editStatusHandler(item.ID);
                                          }
                                        }
                                        fontSize="small"
                                      >
                                        border_color
                                      </Icon>
                                    </div>
                                  )
                                ) : (
                                  item.application_status
                                )}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.waiting_list_number}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.waiting_list_position}
                              </TableCell>
                              <TableCell className={classes.root}>
                                {item.Submission_Date}
                              </TableCell>
                              <TableCell>
                                {item.application_reviewed_status ===
                                  "REVIEWED" && (
                                  <Tooltip
                                    title="Application Reviewed"
                                    interactive
                                    classes={{ tooltip: classes.tooltip_font }}
                                  >
                                    <Icon
                                      style={{
                                        display: "block",
                                        color: "#0B8A00",
                                      }}
                                    >
                                      check_circle
                                    </Icon>
                                  </Tooltip>
                                )}
                                {item.application_reviewed_status ===
                                  "PENDING" && (
                                  <Tooltip
                                    title="Application Pending"
                                    interactive
                                    classes={{ tooltip: classes.tooltip_font }}
                                  >
                                    <Icon
                                      style={{
                                        display: "block",
                                        color: "#FBAD16",
                                      }}
                                    >
                                      pending_actions
                                    </Icon>
                                  </Tooltip>
                                )}
                              </TableCell>
                              <TableCell>
                                <span
                                  onClick={() =>
                                    history.push(
                                      `/admin/applications/${item.student_app_uuid}`
                                    )
                                  }
                                  style={{
                                    // width: 80,
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Icon
                                    style={{
                                      color: "#757575",
                                      marginRight: 10,
                                    }}
                                  >
                                    remove_red_eye_icon
                                  </Icon>
                                  VIEW
                                </span>
                              </TableCell>
                            </>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {error === "" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    margin: "20px 50px",
                  }}
                >
                  <div style={{ padding: "0px 12px" }}>{`${
                    pageNumber || 0
                  } of ${totalPages || 0}`}</div>
                  <button
                    className={classes.firstlastbtn}
                    onClick={() => firstLastPageHandler("first")}
                  >
                    First
                  </button>
                  <div style={{ padding: "0px 12px" }}>
                    <button
                      onClick={() => setPageNumber(pageNumber - 1)}
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                        padding: 0,
                      }}
                      disabled={pageNumber === 1 || pageNumber === 0}
                    >
                      <Icon>chevron_left_outlined</Icon>
                    </button>
                    <button
                      onClick={() => setPageNumber(pageNumber + 1)}
                      style={{
                        border: "none",
                        background: "none",
                        cursor: "pointer",
                        padding: 0,
                      }}
                      disabled={pageNumber === totalPages || pageNumber === 0}
                    >
                      <Icon>chevron_right_outlined</Icon>
                    </button>
                  </div>
                  <button
                    className={classes.firstlastbtn}
                    onClick={() => firstLastPageHandler("last")}
                  >
                    Last
                  </button>
                </div>
              ) : null}
              {error !== "" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  {error}
                </div>
              ) : null}
            </Paper>
          </>
        </div>
      </Box>
    </Grid>
  );
};

export default Applications;
