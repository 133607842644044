import { Welcome } from "../../../components/common/welcome/index";
import { Footer } from "../../../components/common/footer/index";
import { Box } from "@material-ui/core";
import { AddStudent } from "../../../components/common/add-student/index";
import { Info } from "../../../components/common/info/index";
import Header from "../header/index";
import { useStyle } from "./parentdashboard.style";
import { useEffect, useState } from "react";
import { StatusCard } from "../status-card";
import request, { ParentUser } from "../../../utils/authUtil";
import { useQuery, UseQueryResult } from "react-query";
import { useHistory } from "react-router-dom";
import { Loader } from "../../../components/common/loader";
import { CheckNetwork } from "../../../utils/helper";
import moment from "moment";

type Props = {};

interface StudentData {
  uuid: string;
  first_name: string;
  last_name: string;
  application_status: string;
  enrolment_status: string;
  PPSNumber: string;
  gender: string;
  country_of_birth: string;
  religion: string;
  school_application: string;
}

const ParentDashboard = (props: Props) => {
  const styles = useStyle();
  const history = useHistory();
  const schoolID = localStorage.getItem("school-id");
  const parentID = ParentUser.uuid;
  const [loader, setLoader] = useState<boolean>(false);
  const [studentExist, setStudentExist] = useState<boolean>(false);
  const [completeDate, setCompleteDate] = useState<string>("");
  const [completeByTime, setCompleteByTime] = useState<string>("");
  const [openOnTime, setOpenOnTime] = useState<string>("");
  const [openOnDate, setOpenOnDate] = useState<string>("");
  const [canAccessBtn, setCanAccessBtn] = useState<boolean>(false);
  const [userName, setUserName] = useState({
    first: "",
    last: "",
  });

  const [students, setStudents] = useState<StudentData[]>([
    {
      uuid: "",
      first_name: "",
      last_name: "",
      application_status: "",
      enrolment_status: "",
      PPSNumber: "",
      gender: "",
      country_of_birth: "",
      religion: "",
      school_application: "",
    },
  ]);

  const checkIfCanAccessBtn = () => {
    let openDate = moment(openOnDate).format("YYYY-MM-DD");
    let currDate = moment(new Date()).format("YYYY-MM-DD");

    // below condition checks if the openDate is before or after the current date
    // i.e. the application can be submitted or not
    const isBefore = moment(openDate).isSameOrBefore(currDate);
    // if above condition is true, openDate < currDate else openDate > currDate

    // we can check the diff to see how many days of difference there is between openDate and currDate
    // diff = 0 means openDate == currDate
    const diff = moment(openDate).diff(moment(currDate), "days");

    if (isBefore) {
      // the application is open and can be submitted

      // below we check if today is the open date for application
      // ( difference of days between openDate and currDate is zero )
      // and the current time is more than the openOnTime
      if (diff === 0) {
        const currTime = moment().format("HH:mm");
        const openTime = moment(openOnTime, "HH:mm").format("HH:mm");

        if (currTime >= openTime) {
          setCanAccessBtn(true);
        } else {
          // the time for application to open has yet to come
          setCanAccessBtn(false);
        }
      } else {
        setCanAccessBtn(true);
      }
    } else {
      setCanAccessBtn(false);
      // the open date for application is yet to pass
    }
  };

  const getStudents = async (): Promise<any> => {
    const res = await request({
      url: `student-application/student-application-details/?parent_uuid=${parentID}&school_uuid=${schoolID}`,
      method: "get",
    });
    return res;
  };

  const getUserData = async (): Promise<any> => {
    if (parentID) {
      const response = await request({
        url: `/parent/parent-profile-page/?uuid=${parentID}`,
        method: "get",
      });
      return response;
    }
  };

  const { isLoading, data, refetch }: UseQueryResult<any, Error> = useQuery<
    any,
    Error
  >(["students"], () => getStudents(), {
    select: (students) => students.data,
  });

  const getDetails = async () => {
    return request({
      url: `student-application/student-application-dates/?school_uuid=${schoolID}`,
      method: "get",
    }).then((res) => {
      setOpenOnDate(res?.data?.results?.open_date);
      setOpenOnTime(res?.data?.results?.open_time);

      setCompleteByTime(res.data.results.completed_by_time);
      setCompleteDate(res.data.results.completed_by_date);
    });
  };

  useEffect(() => {
    localStorage.removeItem("studentEnrollmentStatus");
    isLoading ? setLoader(true) : setLoader(false);
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      setLoader(false);
      if (data.results.length === 0) {
        setStudentExist(false);
      } else {
        checkIfCanAccessBtn();
        setStudentExist(true);
        setStudents(data.results.application_list);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    getUserData().then((res) => {
      setLoader(false);
      if (res) {
        setUserName({
          ...userName,
          first: res?.data?.results?.first_name,
          last: res?.data?.results?.last_name,
        });
      }
    });
    getDetails();
    localStorage.removeItem("currentStudentAppID");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <Header />
      <CheckNetwork />
      <div className={styles.bodyContainer}>
        <Box mt="50px">
          <Welcome
            title={`Welcome ${userName.first}  ${userName.last}`}
            subTitle=""
            description="Here you can add your student/child details and complete their application. You can add multiple students/children to your account."
          />
        </Box>
        <div>
          {studentExist &&
            students.map((item: any, index: number) => {
              return (
                <div style={{ marginBottom: 50 }} key={index}>
                  <StatusCard
                    studentName={item.first_name + " " + item.last_name}
                    appStatus={item.application_status}
                    enrollStatus={item.enrolment_status}
                    studentID={item.uuid}
                    review={() => history.push("/parent/review-page")}
                    enrolment={() => history.push("/parent/enrolment")}
                    refetch={refetch}
                    setLoader={setLoader}
                    waitingListNumber={
                      item.application_status === "WAITING" &&
                      item.waiting_list_number
                        ? item.waiting_list_number
                        : null
                    }
                    completeDate={completeDate}
                    completeByTime={completeByTime}
                  />
                </div>
              );
            })}
        </div>
        <div className={styles.addStudentContainer}>
          <AddStudent refetchStudents={refetch} isActive={canAccessBtn} />
        </div>
        <Box mt="40px">
          <Info />
        </Box>
        <Box className={styles.footerContainer}>
          <Footer />
        </Box>
      </div>
      <Loader open={loader} />
    </div>
  );
};

export default ParentDashboard;
